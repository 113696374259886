import React, { useState, useEffect } from 'react';
import '../Pages/App.css';

const Process = () => {
   
    return (
        <>
            <div class="processMain" data-aos="zoom-in" data-aos-duration="500">

                <div className='airdropH'>COMMUNITY AIRDROP</div>

                <div class="worksMain" data-aos="fade" data-aos-duration="500">

                    <div class="worksMain2">
                        <div class="worksMain2Div">Step 1</div>
                        <div class="worksMain2DivP">Enter an invite code to claim your airdrop. You can obtain invite codes by joining our Discord or checking our X/Twitter account.</div>
                    </div>

                    <div class="worksMain2">
                        <div class="worksMain2Div">Step 2</div>
                        <div class="worksMain2DivP">Earn Yield + Beast Points by bridging assets to Beast. Your Points will increase gradually as you bridge more assets over time.</div>
                    </div>

                    <div class="worksMain2">
                        <div class="worksMain2Div">Step 3</div>
                        <div class="worksMain2DivP">Share your referral link with your friends and earn 20% referral bonus points.</div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Process;
