import React, { useState, useEffect } from 'react';
import '../Pages/App.css';
import tg from '../assets/telegram2.png';
import mail from '../assets/email2.png';
import twitter from '../assets/twitter2.png';
import logo from '../assets/logo.png';

const Footer = () => {

    return (
        <>
            <div class="footerMain">

            <img className='logoF' src={logo} />

            <div class="footer1">© 2024 BEAST. ALL RIGHT RECEIVED</div>

                    <div class="iconsF">
                        <img src={twitter} />
                        <img src={mail} />
                        <img src={tg} />
                    </div>

            </div>

        </>
    );
};

export default Footer;