import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './Pages/home';
import Leaderboard from './Pages/leaderboard';
import Referrer from './Pages/referrer';
import Deposit from './Pages/deposit';
import Airdrop from './Pages/airdrop';

const App = () => {

	return (
		<>
			<BrowserRouter>

				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/leaderboard' element={<Leaderboard />} />
					<Route path='/referrer' element={<Referrer />} />
					<Route path='/deposit' element={<Deposit />} />
					<Route path='/airdrop' element={<Airdrop />} />

				</Routes>

			</BrowserRouter>

		</>
	);
};

export default App;
