import React, { useState, useEffect, useRef } from 'react';
import '../Pages/App.css';
import Footer from '../components/footer';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import logo from '../assets/logo.png';
import eth2 from '../assets/ethereum (2).png';
import usdt from '../assets/tether.png';
import ThreeLinesSVG from '../components/ThreeLinesSVG';
import dots from '../assets/shape.png';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import { sepolia } from 'wagmi/chains';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

let ABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "referrer",
                "type": "address"
            }
        ],
        "name": "depositETH",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "referrer",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "depositUSDT",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_pointsPerEther",
                "type": "uint256"
            }
        ],
        "name": "setPointsPerEther",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_priceFeedAddress",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "oldOwner",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "TransferOfOwnership",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "allRecords",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "wallet",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "points",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "referrer",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "decimalCorrector",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "depositedConvertedUSDTValue",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "depositedEther",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "depositedUSDT",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "ethToUsd",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getAllDepositRecords",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "index",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "wallet",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "points",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "referrer",
                        "type": "address"
                    }
                ],
                "internalType": "struct DepositAndEarn.UserRecord[]",
                "name": "",
                "type": "tuple[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getLatestPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getTotalDepositedUSDValueForUser",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            }
        ],
        "name": "getUserRecord",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "globalIndex",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "POINTS_PER_USD",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "priceFeed",
        "outputs": [
            {
                "internalType": "contract AggregatorV3Interface",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "referralBonus",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "referrerBonusReceivedOverall",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "token",
        "outputs": [
            {
                "internalType": "contract IERC20",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "totalDepositedUSDValueForUser",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "uniqueUserCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "usdtAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "userRecords",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "wallet",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "points",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "referrer",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

let address = "0x3a560EC54D1719fbb1BDbFFb13910e389fE9d33d";

//TOKEN
let ABI2 = [
    {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Paused",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Unpaused",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "burn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "burnFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "subtractedValue",
                "type": "uint256"
            }
        ],
        "name": "decreaseAllowance",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "addedValue",
                "type": "uint256"
            }
        ],
        "name": "increaseAllowance",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "pause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "unpause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

let address2 = "0xea1654e8Fa3Cf1DAd65F6856ccAdbF77dFBE086F";


const Deposit = () => {
    const [selectedCurrency, setSelectedCurrency] = useState('ETH');
    const [_depositOpen, set_depositOpen] = useState(1);
    const [_withdrawOpen, set_withdrawOpen] = useState(0);
    const [_navbarOpen, set_navbarOpen] = useState(0)
    const [_etherOpen, set_etherOpen] = useState(0);
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];
    const { open } = useWeb3Modal()
    const { chain } = useNetwork()
    const { switchNetwork } = useSwitchNetwork()
    const [_connected, setConnected] = useState(false)
    const [_myRefLink, set_refLink] = useState("");
    const [_refAddress, set_refAddress] = useState("");
    const [_amount, set_Amount] = useState(0);
    const [statusError, setstatusError] = useState(false)
    const [statusLoading, setstatusLoading] = useState(false)
    const [success, setsuccess] = useState("")
    const [_value, set_value] = useState(0);
    const [_allowance, set_allowance] = useState(0);
    const [_depositedUSDT, set_depositedUSDT] = useState(0);
    const [_approvalClear, set_approvalClear] = useState(false);

    var contract = {
        address: address,
        abi: ABI
    }

    var contract2 = {
        address: address2,
        abi: ABI2
    }

    const { writeAsync } = useContractWrite({
        //...contract,
        //functionName: 'mint',
        onError(error) {
            if (error.message.includes('balance')) {
                setstatusError(true)
                setstatusLoading(false)
            }
        }
    })


    const { address: walletAddress } = useAccount({
        async onConnect() {
            handleConnect()
        }
    })


    var url = "http://localhost:3000/?ref=";
    var refLink = url.concat(walletAddress);


    const publicClient = createPublicClient({
        chain: sepolia,
        transport: http()
    })

    function focusNext(index) {
        if (index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
    }

    const { refetch: getAllowance } = useContractRead({
        ...contract2,
        functionName: 'allowance', args: [walletAddress, address]
    })

    const { refetch: getDepositedUSDT } = useContractRead({
        ...contract,
        functionName: 'depositedUSDT', args: [walletAddress]
    })

    function handleInputChange(event, index) {
        const maxLength = 1; // Maximum length of input
        const inputValue = event.target.value;

        if (inputValue.length >= maxLength) {
            focusNext(index);
        }
    }


    async function closeNav() {
        set_navbarOpen(0);
    }


    async function navbarOpen() {
        set_navbarOpen(1);
    }


    async function handleConnect() {
        if (chain.id !== 11155111) {
            switchNetwork(11155111)
        }

        setConnected(true);
    }


    async function disconnectF() {
        setConnected(false);

        window.location.reload(true);

    }


    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
    };

    async function closeNav() {
        set_navbarOpen(0);
    }

    async function navbarOpen() {
        set_navbarOpen(1);
    }

    async function depositOpen() {
        set_depositOpen(1);
        set_withdrawOpen(0);
    }

    async function withdrawOpen() {
        set_withdrawOpen(1);
        set_depositOpen(0);
    }

    async function depositETH() {

        try {
            setstatusLoading(true);
            var res = await writeAsync({
                ...contract,
                functionName: 'depositETH',
                args: ["0xe81A538894A0FfAb768aA64F724cc618B1961A14"],
                value: parseEther(_value),
                gasLimit: '685000'
            })

            var result = await publicClient.waitForTransactionReceipt(res)
            if (result.status === 'success') {
                setstatusError(false)
                setsuccess(true)
                setstatusLoading(false)
                await new Promise(resolve => setTimeout(resolve, 5000));
                window.location.reload(true);
            }
            else {
                setsuccess(false)
                setstatusError(true)
                setstatusLoading(false)
            }
        } catch (err) {
            setsuccess(false)
            setstatusError(true)
            setstatusLoading(false)
            console.log(err);
        }

    }

    async function depositUSDT() {

        try {
            setstatusLoading(true);
            var res = await writeAsync({
                ...contract,
                functionName: 'depositUSDT',
                args: ["0xe81A538894A0FfAb768aA64F724cc618B1961A14", Number(_amount * 10 ** 9)],
                //value: parseEther(_value),
                gasLimit: '685000'
            })

            var result = await publicClient.waitForTransactionReceipt(res)
            if (result.status === 'success') {
                setstatusError(false)
                setsuccess(true)
                setstatusLoading(false)
                await new Promise(resolve => setTimeout(resolve, 5000));
                window.location.reload(true);
            }
            else {
                setsuccess(false)
                setstatusError(true)
                setstatusLoading(false)
            }
        } catch (err) {
            setsuccess(false)
            setstatusError(true)
            setstatusLoading(false)
            console.log(err);
        }

    }

    async function approve() {
        console.log("check");
        try {
            setstatusLoading(true);
            var res = await writeAsync({
                ...contract2,
                functionName: 'approve',
                args: [address, Number(_amount * 10 ** 18)],
                //value: parseEther(_value),
                gasLimit: '685000'
            })

            var result = await publicClient.waitForTransactionReceipt(res)
            if (result.status === 'success') {
                setstatusError(false)
                setsuccess(true)
                setstatusLoading(false)
                await new Promise(resolve => setTimeout(resolve, 5000));
                window.location.reload(true);
            }
            else {
                setsuccess(false)
                setstatusError(true)
                setstatusLoading(false)
            }
        } catch (err) {
            setsuccess(false)
            setstatusError(true)
            setstatusLoading(false)
            console.log(err);
        }

    }

    useEffect(() => {

        if (window.location.href != null) {

            let currentUrl = window.location.href;
            console.log("currentUrl" + currentUrl);

            // https://dtc-mint.pinkribbons.club/?ref=0x6334BAE02114C080F05E6D58b65A1d7926FbbeBc

            let arr = currentUrl.split("=");
            console.log("arr:" + arr[1]);

            var refAddress = arr[1];
            set_refAddress(refAddress);

            if (refAddress != null) {
                /*console.log("zzzzzzzz :" + localStorage?.getItem('refWindowOpenedBefore'));
                if (localStorage?.getItem('refWindowOpenedBefore') > 0) {
                    localStorage.setItem('refWindowOpenedBefore', 1);

                    this.setState({ _windowOpen: 0 });
                    console.log("refWindowOpen1 : ");
                } else {
                    localStorage.setItem('refWindowOpenedBefore', 1);

                    this.setState({ _windowOpen: 1 });
                    console.log("refWindowOpen2 : ");
                }*/


            }

        }

        async function fetchGetAllowance() {
            var data1 = await getAllowance();
            set_allowance(data1.data);
        }

        async function fetchGetDepositedUSDT() {
            var data1 = await getDepositedUSDT();
            set_depositedUSDT(data1.data);
        }

        async function checkAllowanceAmount() {
            if (Number(_allowance) > (Number(_depositedUSDT) + Number(_amount))) {
                set_approvalClear(true);
            } else {
                set_approvalClear(false);
            }

            console.log("_approvalClear : " + _approvalClear);
            console.log("_allowance : " + _allowance);
            console.log("_depositedUSDT : " + _depositedUSDT);
            console.log("_amount : " + _amount);
        }

        if (_connected) {
            fetchGetAllowance();
            fetchGetDepositedUSDT();
            checkAllowanceAmount();
        }

        // eslint-disable-next-line no-use-before-define
    }, [walletAddress, _amount
		  /*getRewardRatePerSecond, _rewardRatePerSecond, _receive*/]);

    function shortenAddress(walletAddress) {
        return walletAddress.slice(0, 3) + "..." + walletAddress.slice(-4);
    }

    return (
        <>
            <div class="allWrap">
                <div class="light">
                    <ThreeLinesSVG />

                    <div class="headers">

                        <div class="h1">
                            <div class="logoDiv">
                                <img onClick={() => window.location.href = '/'} src={logo} />
                                <img className='dots' src={dots} />

                                <div class="connect">
                                    <div id="fontSize" onClick={() => window.location.href = 'airdrop'}>Airdrop</div>
                                    <div onClick={() => window.location.href = 'leaderboard'} id="fontSize">Leaderboard</div>
                                    <div id="fontSize">Dev</div>
                                    <div id="fontSize">About</div>
                                </div>

                            </div>

                            {!_connected ?
                                <button class="connectBtn" onClick={() => { open() }}>Connect</button> :
                                <button class="connectBtn" onClick={disconnectF}>{shortenAddress(walletAddress)}</button>
                            }
                            {/*<div className='web3'><Web3Button /></div>*/}


                        </div>

                    </div>

                    {_navbarOpen < 1 ?
                        (<div class="miniBarMain">
                            <div class="logoDiv"><img src={logo} /></div>

                            <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                        </div>) :
                        (<div class="miniBarMain">
                            <div class="logoDiv"><img src={logo} /></div>

                            <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                        </div>)}

                    {_navbarOpen > 0 ?
                        (<div class="littleNav">
                            <div id="fontSize" onClick={() => window.location.href = 'airdrop'}>Airdrop</div>
                            <div onClick={() => window.location.href = 'leaderboard'} id="fontSize">Leaderboard</div>
                            <div id="fontSize">Dev</div>
                            <div id="fontSize">About</div>

                        </div>) : null}


                    <Element name="airdrop">
                        <div class="depositMain">
                            <div className='depositDetails'>
                                <div className='depositH'>Bridge to Earn Yield + <br /><span className='color'>BEAST POINTS</span></div>
                                <div className='depositH2'>Bridging selected assets to Beast will result in earning yield + Beast points</div>
                                <p className='depositH2'>Refer friends to Beast and earn 20% of the points your friends accumulate during the campaign by referring them to join Beast.</p>
                            </div>

                            <div class="depositMain2">

                                <div className='depositBoxNav'>
                                    {_depositOpen > 0 ?
                                        (<div onClick={depositOpen}>DEPOSIT</div>) :
                                        (<div className='inDepositBox2' onClick={depositOpen}>DEPOSIT</div>)
                                    }

                                    {_withdrawOpen > 0 ?
                                        (<div onClick={withdrawOpen}>WITHDRAW</div>) :
                                        (<div className='inDepositBox' onClick={withdrawOpen}>WITHDRAW</div>)
                                    }
                                </div>

                                {_depositOpen > 0 ?
                                    (
                                        <div>
                                            {selectedCurrency === 'ETH' ?
                                                < div class="depositMain2-2">

                                                    <div className='depoSubBox'>

                                                        <div className='depoSubBoxH'>FROM</div>

                                                        <div className='depoSubBox2'>
                                                            <img src={eth2} />
                                                            <div>Ethereum Mainnet</div>
                                                        </div>

                                                        <div className='inputDeposit'>
                                                            <div className='inputDeposit1'>

                                                                <input type="text" id="multisigAddress" name="multisigAddress" placeholder="Enter multisig address..."
                                                                    value={_value}
                                                                    onChange={event => set_value(event.target.value)}
                                                                />
                                                            </div>

                                                            <div className='inputDeposit2'>
                                                                {selectedCurrency === 'ETH' && <img src={eth2} alt="ETH" />}
                                                                {selectedCurrency === 'USDT' && <img src={usdt} alt="USDT" />}
                                                                <select value={selectedCurrency} onChange={handleCurrencyChange}>
                                                                    <option value="ETH">ETH</option>
                                                                    <option value="USDT">USDT</option>
                                                                </select>
                                                            </div>

                                                        </div>

                                                        <div className='balance'>
                                                            <img className='balanceI' src={eth2} />
                                                            <span className='balance3'>MAX</span>
                                                        </div>

                                                        <div className='linesMain'>
                                                            <div className='lines'></div>
                                                            <div className='lineM2'>TO</div>
                                                            <div className='lines'></div>
                                                        </div>

                                                        <div className='depoSubBox2'>
                                                            <img src={logo} />
                                                            <div>Beast L2</div>
                                                        </div>

                                                        <div className='note'>You will receive 0.00 ETH + Yield + Spins for points</div>

                                                    </div>

                                                    <button className='submit2' onClick={depositETH}>SUBMIT</button>

                                                </div> :

                                                <div class="depositMain2-2">

                                                    <div className='depoSubBox'>

                                                        <div className='depoSubBoxH'>FROM</div>

                                                        <div className='depoSubBox2'>
                                                            <img src={eth2} />
                                                            <div>Ethereum Mainnet</div>
                                                        </div>

                                                        <div className='inputDeposit'>
                                                            <div className='inputDeposit1'>

                                                                <input type="text" id="multisigAddress" name="multisigAddress" placeholder="Enter multisig address..."
                                                                    value={_amount}
                                                                    onChange={event => set_Amount(event.target.value)}
                                                                />
                                                            </div>

                                                            <div className='inputDeposit2'>
                                                                {selectedCurrency === 'ETH' && <img src={eth2} alt="ETH" />}
                                                                {selectedCurrency === 'USDT' && <img src={usdt} alt="USDT" />}
                                                                <select value={selectedCurrency} onChange={handleCurrencyChange}>
                                                                    <option value="ETH">ETH</option>
                                                                    <option value="USDT">USDT</option>
                                                                </select>
                                                            </div>

                                                        </div>

                                                        <div className='balance'>
                                                            <img className='balanceI' src={eth2} />
                                                            <span className='balance3'>MAX</span>
                                                        </div>

                                                        <div className='linesMain'>
                                                            <div className='lines'></div>
                                                            <div className='lineM2'>TO</div>
                                                            <div className='lines'></div>
                                                        </div>

                                                        <div className='depoSubBox2'>
                                                            <img src={logo} />
                                                            <div>Beast L2</div>
                                                        </div>

                                                        <div className='note'>You will receive 0.00 ETH + Yield + Spins for points</div>

                                                    </div>

                                                    {_approvalClear ?
                                                        <button className='submit2' onClick={depositUSDT}>SUBMIT</button> :
                                                        <button className='submit2' onClick={approve}>Approve</button>
                                                    }

                                                </div>}
                                        </div>
                                    ) : null}

                                {_withdrawOpen > 0 ?
                                    (<div class="depositMain2-2">
                                    </div>) : null}

                            </div>
                        </div>
                    </Element>
                </div >
                <Footer />

            </div >

        </>
    );
};

export default Deposit;
